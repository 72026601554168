/* import { Link } from 'react-bootstrap-icons'; */
import React from "react";
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Categorias from '../components/categorias.js';
/* import Carousel from '../components/carousel.js'; */
import Productos from '../components/Productos.js';
/* ||| */
/* import Productos2 from '../components/Productos2.js'; */
function App() {

  return (
    <>
      <Video nombre="../assets/video/toytek.mp4"
        caption="portada"
        replay={true}
      />
      <div>
        <Productos categoriaId="13" categoriaNombre="Productos Destacados" />
      </div>
      <div>
        <Categorias></Categorias>
      </div>
      <div>
        <Productos categoriaId="14" categoriaNombre="Productos Recientes" />
      </div>
      <Wsp></Wsp></>
  );
}

export default App;