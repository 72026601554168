import React from 'react';
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import BreadcrumbSAdmin from '../components/breadcrumbSadmin.js';
import Galeria from '../components/galeria_productosCategorias.js';
function ProductosSAdmin() {
  return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><BreadcrumbSAdmin categoria={'Categoría de Productos'} descripcion={'Categoría de Productos'}></BreadcrumbSAdmin>
   <Galeria />
    <Wsp></Wsp></>
);
}

export default ProductosSAdmin;