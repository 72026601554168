import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
/* import reportWebVitals from './reportWebVitals'; */
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');

// Hidratar usando React 18


if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement,  
    <HelmetProvider>
  <BrowserRouter>
    <App />
</BrowserRouter></HelmetProvider>);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<HelmetProvider>
    <BrowserRouter>
      <App />
  </BrowserRouter></HelmetProvider>);
}

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
  </HelmetProvider>
); */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
