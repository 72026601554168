import React from "react";
import CardElementos from './CardElementos.js';
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import '../css/galeriaVideos.css';
import Breadcrumb from './breadcrumb.js';

function VideoDataIntro() {

  const imagePathPaso= '/assets/videos-cartoons/intro/';
/*   const imagePathPaso + arrGijoeSeries5[0][0] +  finalImg = '/images/gi-joe/'; */

const temporadasVideosIntro = [{ id: "1", titulo: "HE-MAN Y LOS AMOS DEL UNIVERSO", temporada: "2 Temporadas", url: "he-man-y-los-amos-del-universo-filmation"},
  { id: "2", titulo: "EL SHOW DE LA PANTERA ROSA", temporada: "15 Temporadas", url: "el-show-de-la-pantera-rosa"},
  { id: "3", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "2 Temporadas", url: "gi-joe-un-verdadero-heroe-americano"},
  { id: "4", titulo: "CAPITAN CENTELLA", temporada: "32 Episodios", url: "capitan-centella"},];
  return (<>
    <Video  nombre="/assets/video/toytek.mp4" 
            caption="portada" 
            replay={true} 
     />
     <div style={{paddingTop: '10px'}}>
     <Breadcrumb categoria={'Galeria de videos'} descripcion={'Cartoons'} url={'/galeria-de-videos/'}></Breadcrumb>
     <div className="container">
       
          {temporadasVideosIntro.map((video) => (
            <div className='row_videos'><div className="col-md-4" key={video.id}>
            <CardElementos 
              id={video.id} 
              nombre={video.titulo} 
              serie={video.temporada} 
              ruta={imagePathPaso + video.id + '.jpg'} 
              url={video.url}
            />
          </div></div>
        ))}
        </div></div></>
    );
}

export default VideoDataIntro;
