import React, { useEffect, useState } from 'react';
import '../App.css';
import dataCategorias from "../assets/categorias.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import axios from 'axios';

function App() {

    const [categorias, setCategorias] = useState(dataCategorias || []);
    
    useEffect(() => {
    // Realizar la solicitud GET a la API
    if (categorias) {
        setCategorias(categorias);
       /*  return categorias; */
    } else {

    axios.get('https://mp.toytek.cl/scrud/obtener-categorias')
        .then(response => {
            setCategorias(response.data); // Almacena los datos en el estado
        })
        .catch(error => {
            setCategorias(dataCategorias);
            console.error('Error al obtener las Categorias:', error);
        });
    }
    }, []);

    const formatThumbnail = (url) => {
        if (!url) return 'https://toytek.cl/assets/logo/toytek.jpg'; // Fallback por defecto
        if (url.startsWith('../')) return url.replace('../', 'https://toytek.cl/');
        return url;
    };
    
return (
<div className="container-fluid">
<h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4" style={{paddingTop: '40px'}}><span className="bg-secondary pr-3">Categorías de Productos</span></h2>
<div className="row px-xl-5 pb-3">{
    categorias.length > 0 &&
    categorias.map((categoria, i)=>{

    return (<div className="col-lg-3 col-md-4 col-sm-6 pb-1" key={i}>
                  <Link  key={`link_${categoria.id}`} className="text-decoration-none" to={categoria.url}>
                      <div className="cat-cat d-flex align-items-center mb-4">
                          <div className="overflow-hidden" style={{width: '100px', height: '100px'}}>
                              <LazyLoadImage key={`imagen_${categoria.id}`} className="img-fluid" src={formatThumbnail(`${categoria.imagen}`)} alt=""/>
                          </div>
                          <div className="flex-fill pl-3">
                              <p  key={`titulo_${categoria.id}`} style={{color: '#fff'}}>{categoria.titulo}</p>
                              <small  key={`descripcion_${categoria.id}`} className="text-body" style={{color: '#fff'}}><b>{categoria.cantidad_productos}</b> Productos</small>
                          </div>
                      </div>
                  </Link>    
              </div>)
})

}</div></div>)

}

export default App;