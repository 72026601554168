import React from "react";
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Carro from '../components/Carro.js';
import Breadcrumb from '../components/breadcrumb.js';
function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Breadcrumb categoria="Compra" descripcion="Carro de Compras"/><Carro />
    <Wsp></Wsp></>
);
}

export default App;