import React from "react";
import CardElementos from './CardElementos.js';
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import '../css/galeriaVideos.css';
function VideoDataIntro() {

const temporadasVideosIntro = [
  { id: "1", titulo: "CARTOONS", temporada: "DIBUJOS ANIMADOS", url: "/galeria-de-videos/cartoons/", paso: "/assets/videos-cartoons/inicio/"},
  { id: "2", titulo: "MOVIES", temporada: "PELÍCULAS", url: "/galeria-de-videos/movies/", paso: "/assets/videos-movies/inicio/"},];
  return (<>
    <Video  nombre="/assets/video/toytek.mp4" 
            caption="portada" 
            replay={true} 
     />
     <div className="container" style={{alignItems: 'center', justifyContent: 'center'}}>
       
          {temporadasVideosIntro.map((video) => (
            <div className='row_videos'><div className="col-md-4" key={video.id}>
            <CardElementos 
              id={video.id} 
              nombre={video.titulo} 
              serie={video.temporada} 
              ruta={video.paso + video.id + '.jpg'} 
              url={video.url}
            />
          </div></div>
        ))}
        </div></>
    );
}

export default VideoDataIntro;
