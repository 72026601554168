import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../css/style.css';
import '../App.css';
import { useForm } from 'react-hook-form';
import { XLg } from 'react-bootstrap-icons';
import { initMercadoPago } from '@mercadopago/sdk-react'
import axios from "axios";

function App() {
    const nf = new Intl.NumberFormat("es-CL");
    const {
        register,
        handleSubmit,
    } = useForm();

    const form2 = useRef();
    const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("toytek-carrito")) || []);
    const [cadenaProductos, setCadenaProductos] = useState(null);
    const [formData, setFormData] = useState(JSON.parse(localStorage.getItem("toytek-formulario")) || {
        email: ''
        });
    const [usuario, setUsuario] = useState(null);
    const [botonHabilita, setBotonHabilita] = useState(true);
    const [preferenceIdi, setpreferenceId] = useState(null);

    // Inicializar MercadoPago
    useEffect(() => {
        initMercadoPago(process.env.REACT_APP_KEYPUBLIC, {
            locale: "es-CL",
        });
    }, []);

     // Cargar datos de localStorage en el cliente
    useEffect(() => {
        const carrito = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
        const formulario = JSON.parse(localStorage.getItem("toytek-formulario")) || { email: "" };
        console.log("Datos cargados desde localStorage:", { carrito, formulario });
        setProductosEnCarrito(carrito);
        setFormData(formulario);
        setCadenaProductos(
        carrito.map((producto) => `${producto.id}, `).join("\n")
        );
    }, []);

    // Actualizar `localStorage` cuando el carrito o formulario cambien
    useEffect(() => {
        localStorage.setItem("toytek-carrito", JSON.stringify(productosEnCarrito));
        localStorage.setItem("toytek-formulario", JSON.stringify(formData));
    }, [productosEnCarrito, formData]);
    
/*     const handleClick = (e) => {

        eliminarDelCarrito(e);
        setProductosEnCarrito(JSON.parse(localStorage.getItem("toytek-carrito")));
    }; */

    const eliminarDelCarrito = (e) => {
        /* const idBoton = e.currentTarget.id;
        const index = productosEnCarrito.findIndex((producto) => producto.id === String(idBoton));
        productosEnCarrito.splice(index, 1);
        localStorage.setItem("toytek-carrito", JSON.stringify(productosEnCarrito)); contar carriot y bloquear boton
        revisaForm(); */
        

       /*  const updatedCarrito = productosEnCarrito.filter(
            (producto) => producto.id !== id
          );
          setProductosEnCarrito(updatedCarrito);
          revisaForm(updatedCarrito);

          window.dispatchEvent(new Event("storage")); */
          const idBoton = e.currentTarget.id;
          const index = productosEnCarrito.findIndex((producto) => producto.id === String(idBoton));
          productosEnCarrito.splice(index, 1);
          localStorage.setItem("toytek-carrito", JSON.stringify(productosEnCarrito));
          revisaForm();
          setProductosEnCarrito([...productosEnCarrito]); // Forzar la actualización
          window.dispatchEvent(new Event("storage"));
    }



/*     useEffect(() => {
        cargarProductos();
        revisaForm();
    },); */

    /* let TvalorProductoArt = 0, TvalorProductoArtFormat = 0;
    TvalorProductoArt = productosEnCarrito.reduce((acc, item) => acc + Math.floor(item.valor), 0); */

    //3
    const handleBuy = async () => {
        try {
            const id = await createPreference();
            if (id) {
                setpreferenceId(id); // Esto desencadenará el useEffect
                enviarDatos(id);     // Puedes dejar esto aquí si no depende del useEffect
                /*  enviarCliente();  */    // Puedes dejar esto aquí si no depende del useEffect

            }
        } catch (error) {
            console.error("Error al realizar la compra:", error);
        }
    };

    const onSubmit = async () => {
        /*    e.preventDefault(); */
        // Guardar los datos del formulario en el localStorage
        localStorage.setItem('toytek-formulario', JSON.stringify(formData));
        console.log('Datos guardados en localStorage:', formData);
    };

    const createPreference = async () => {
        try {
            /* console.log('Enviando solicitud a la API...'); */

            const response = await axios.post("https://mp.toytek.cl/create_preference", {
                title: cadenaProductos,
                quantity: 1,
                price: productosEnCarrito.reduce(
                    (acc, item) => acc + Math.floor(item.valor),
                    0
                  ),
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest' // Reemplaza con tu token válido
                },
                withCredentials: true, // Si necesitas enviar cookies entre dominios
            });

            if (response.data && response.data.redirectUrl) {
                // Redirige al usuario a la URL de pago de MercadoPago
                window.location.href = response.data.redirectUrl;
            } 
                return response.data?.id || null;

        } catch (error) {
            console.error('Error al crear la preferencia de compra:', error);
        }
    };

/*     useEffect(() => {
        if (preferenceIdi) {
            console.log("Preference ID actualizado:", preferenceIdi);
            // Aquí puedes realizar cualquier acción necesaria, como mostrar el componente Wallet
        }
    }, [preferenceIdi]); */

/*     useEffect(() => {
        localStorage.setItem('toytek-formulario', JSON.stringify(formData));
    }, [formData]); */

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
      /*   localStorage.setItem('toytek-formulario', JSON.stringify(formData)); */
    };

    const enviarDatos = useCallback(async (id) => {
        try {

           await axios.post('https://mp.toytek.cl/scrud/insertar-ventas', {
                productos: productosEnCarrito,
                usuario,
                id
            });
           
        } catch (error) {
            console.error('Error al enviar productos:', error);
        }
    }, [productosEnCarrito, usuario]);

/*     const cargarProductos = () => {
        const productos = JSON.parse(localStorage.getItem("toytek-carrito")) || [];

        if (productos !== null) {
            setProductosEnCarrito(productos);
            const cadenaProductosString = productosEnCarrito.map(producto => {
                return `${producto.id}, `;
            }).join('\n');
            setCadenaProductos(cadenaProductosString);
        }
    };
 */
    const revisaForm = (carrito = productosEnCarrito) => {
        if (formData.email && carrito.length > 0) {
          setBotonHabilita(false);
          setUsuario(formData.email);
        } else {
          setBotonHabilita(true);
        }
      };

/*     const revisaForm = () => {
        if (document.getElementById('email').value !== '' && productosEnCarrito.length > 0) {
            setbotonHabilita(false);
            setUsuario(document.getElementById('email').value);
        }
        else {
            setbotonHabilita(true);

        }
    }; */

    return (<div className="container-fluid">
        <div className="row px-xl-5">
            <div className="col-lg-8 table-responsive mb-5">
                <table className="table table-light table-borderless table-hover text-center mb-0">
                    <thead className="thead-dark">
                        <tr>
                            <th>Productos</th>
                            <th>Precio</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">

                        {productosEnCarrito.map((producto) => {
                               {/*  let valorProductoArt = `$${nf.format(Math.floor(producto.valor))}`;

                                TvalorProductoArtFormat = `$${nf.format(TvalorProductoArt)}`;
                                let foto1 = '';
                                if (producto.categorias === 15 || producto.categorias === 16) {
                                    foto1 = `${producto.foto1}`;
                                } else {
                                    foto1 = `${producto.foto1}_i.JPG`;
                                } */}

                                return (

                                    <tr key={`${producto.id}`} >
                                        <td className="align-middle" style={{ textAlign: 'left' }} id="title">
                                        <img
                                            src={(producto.categorias === 15 || producto.categorias === 16) ? `${producto.foto1 || ""}` : `${producto.foto1}_i.JPG`}
                                            alt=""
                                            style={{ width: "50px" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;{producto.id}-{producto.descripcion}</td>
                                        <td className="align-middle" id="price">{`$${nf.format(producto.valor)}`}</td>
                                        <td className="align-middle"><button className="btn btn-sm btn-danger carrito-producto-eliminar" id={`${producto.id}`} onClick={(e) => eliminarDelCarrito(e)}><XLg size={18} color='white'></XLg></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-lg-4">
                <form className="mb-30" id="form" ref={form2} onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group">
                        <label className="btn btn-primary">E-mail</label>
                        <input className="form-control" type="email" id="email" name="email"
                            placeholder="Ingresa tu E-mail"
                            {...register("email", {
                                required: "El email es obligatorio",
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: "Formato de email inválido",
                                },
                            })} onKeyUp={revisaForm} onChange={handleChange} autoComplete='email' value={formData.email} />
                    </div>

                    <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3" style={{ color: 'white' }}>Resumen</span></h5>
                    <div className="bg-light p-30 mb-5">
                        <div className="border-bottom pb-2">
                            <div className="d-flex justify-content-between">
                                <h6 className="font-weight-medium">Despacho <span style={{ color: 'blue', fontWeight: 'lighter' }}>STARKEN</span><span style={{ color: 'blue', fontWeight: 'bold' }}> VALPARAÍSO</span></h6>
                                <h6 className="font-weight-medium">por PAGAR</h6>
                            </div>
                        </div>
                        <div className="pt-2">
                            <div className="d-flex justify-content-between mt-2">
                                <h5>Total</h5>
                                <h5>$ {nf.format(productosEnCarrito.reduce(
            (acc, item) => acc + Math.floor(item.valor),
            0
          ))}</h5>
                            </div>
                            <h6 style={{ textAlign: 'right' }}>Impuesto incluido</h6>
                            <button className="btn btn-block btn-primary font-weight-bold py-3" onClick={handleBuy} type='submit' disabled={productosEnCarrito.length === 0 || preferenceIdi?.length > 0 || botonHabilita} >
                                {"Pagar"}
                            </button><br></br>
                            <p>* Se requiere su E-mail para pagar.</p>
                            <p>* Para Transferencias me puede hablar al <b>WhatasApp</b>.</p>
                            <p>* Pagos con cuenta <b>MercadoPago</b>.</p>
                            <p>* Pagos directos con <b>Tarjeta de Crédito, Débito y Prepago</b>.</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>);
}

export default App;