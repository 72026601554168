import React from "react";
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import DGProductos from '../components/dataGrid1.js';
import BreadcrumbSAdmin from '../components/breadcrumbSadmin.js';

function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
   <div style={{zIndex: '998', position: 'relative' }}><BreadcrumbSAdmin></BreadcrumbSAdmin><DGProductos /></div>
    <Wsp></Wsp></>
);
}

export default App;