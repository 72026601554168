import React from "react";
import '../css/footerO.css';
import { Link } from 'react-router-dom';
import { Youtube, Instagram, Facebook, Tiktok, CameraReels, PinMapFill, CaretRightFill, EnvelopeAtFill } from 'react-bootstrap-icons';

function footerCSS() {


    return (<div className="container-fluid bg-dark text-secondary mt-5 pt-5" style={{ position: 'relative', zIndex: 1, top: '200px' }}>
        <div className="row px-xl-5 pt-5">
            <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                <img src="/assets/logo/toytek.png" title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" style={{ paddingBottom: '10px' }} />
                <p className="mb-4-footer">Juguetes Retro Nostálgicos Coleccionables de los 70/80/90.</p>
                <p className="mb-2"><PinMapFill size={20} ></PinMapFill> Valparaíso, Chile, 🇨🇱</p>
                <p className="mb-2"><EnvelopeAtFill size={20}></EnvelopeAtFill> hola@toytek.cl</p>
                <p>🇨🇱&nbsp;&nbsp;</p>
          <p><img src={`/assets/logo/mercadopago.png`} alt="mercadopago" title="mercadopago" aria-label="mercadopago" key={'mercadopago'} width="123px" height="32px" /></p>
            </div>
            <div className="col-lg-8 col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-5">
                        <h2 className="text-secondary-footer-h5 text-uppercase-footer mb-4-footer">Enlaces Rápidos</h2>
                        <div className="d-flex flex-column justify-content-start">
                            <Link to="/home" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Inicio</Link>
                            <Link to="/collections/figuras-de-accion" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Acción</Link>
                            <Link to="/collections/figuras-articuladas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Articuladas</Link>
                            <Link to="/collections/autitos" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Autitos</Link>
                            <Link to="/collections/figuras-monocromaticas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Monocromáticas</Link>
                            <Link to="/collections/figuras-plasticas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Plásticas</Link>
                            <Link to="/collections/figuras-de-pvc" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>PVC</Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <h2 className="text-secondary-footer-h5 text-uppercase-footer mb-4-footer">+</h2>
                        <div className="d-flex flex-column justify-content-start">
                            <Link to="/collections/papeleria" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Papelería</Link>
                            <Link to="/collections/peliculas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Películas</Link>
                            <Link to="/collections/poleras" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Poleras</Link>
                            <Link to="/collections/promocionales" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Promocionales</Link>
                            <Link to="/collections/rompecabezas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Rompecabezas</Link>
                            <Link to="/collections/tazas" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Tazas</Link>
                            <Link to="/collections/videojuegos" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Videojuegos</Link>
                            <Link to="/collections/variados" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>Variados</Link>
                            <Link to="/collections/all" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill>TODOS</Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                    <h2 className="text-secondary-footer-h5 text-uppercase-footer mb-4-footer">Servicio al cliente</h2>
                        <div className="d-flex flex-column justify-content-start">
                            <Link to="/account/logout" className="text-secondary mb-2" ><CaretRightFill size={16}></CaretRightFill>Salir</Link>
                            <Link to="/account/forgot-password" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill> Olvidé mi contraseña</Link>
                            <Link to="/account/foreigners" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill> Soy extranjero</Link>
                            <Link to="/pages/contact" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill> Contacto</Link>
                            <Link to="/pages/pagos-y-despachos" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill> Pagos y Despachos</Link>
                            <Link to="/cart" className="text-secondary mb-2"><CaretRightFill size={16}></CaretRightFill> Carro de compras</Link>
                        </div>
                        <h2 className="text-secondary text-uppercase mt-4 mb-3">Síganos</h2>
                        <div className="d-flex">
                            <Link to="https://www.youtube.com/@toytek_cl" className="btn btn-primary btn-square mr-2" target="_blank" title="Youtube de TOYTEK" aria-label="Youtube de TOYTEK">
                                <Youtube color='white' size={20}></Youtube>
                            </Link>
                            <Link to="https://www.instagram.com/toytek.cl/" className="btn btn-primary btn-square mr-2" target="_blank" title="Instagram de TOYTEK" aria-label="Instagram de TOYTEK"><Instagram color='white' size={16}></Instagram></Link>
                            <Link to="https://www.facebook.com/toytek.cl/" className="btn btn-primary btn-square mr-2" target="_blank" title="Facebook de TOYTEK" aria-label="Facebook de TOYTEK"><Facebook color='white' size={16}></Facebook></Link>
                            <Link to="https://tiktok.com/@toytek.cl" className="btn btn-primary btn-square mr-2" target="_blank" title="Tiktok de TOYTEK" aria-label="Tiktok de TOYTEK"><Tiktok color='white' size={16}></Tiktok></Link>
                            <Link to="/galeria-de-videos/" className="btn btn-primary btn-square mr-2" target="_blank" title="Galería de videos de TOYTEK" aria-label="Galería de videos de TOYTEK"><CameraReels size={16}></CameraReels></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row border-top mx-xl-5 py-4" style={{ borderColor: 'rgba(256, 256, 256, .1), !important' }}>
            <div className="col-md-6 px-xl-0">
                <p className="mb-md-0 text-center text-md-left text-secondary">
                    &copy; 1998-2025 TOYTEK. Reservados todos los derechos. | <Link to="https://applite.cl/"  className="text-primary-footer" target="_blank">Diseñado por APP LITE</Link>
                </p>
            </div>
            <div className="col-md-6 px-xl-0 text-center text-md-right">
                <img className="img-fluid" src="/assets/logo/payments.png" alt="" />
            </div>
        </div>
    </div>);
};

export default footerCSS;
