
import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RequireAuth from './components/requireAuth.js';
import Portada from './pages/Portada.js';
import PortadaSign from './pages/PortadaSign.js'; 
import Registro from './pages/Registro.js';
import PagosDespachos from './pages/PagosDespachos.js';
import Login from './pages/Login.js';
/* import Login from './pages/SignIn.js'; */
import Contacto from './pages/Contacto.js';
import Cart from './pages/Cart.js';
import Unsubscribe from './pages/Unsubscribe.js';
import Me from './pages/Me.js';
import OrdenCompraOk from './pages/OrdenCompraOk.js';
import OrdenCompraError from './pages/OrdenCompraError.js';
import OrdenCompraPendiente from './pages/OrdenCompraPendiente.js';
/* import Checkout from './pages/Checkout.js'; */
import Search from './pages/Search.js';
import TestChatBot from './pages/TestChatBot.js';
import DetalleProducto from './pages/DetalleProducto.js';
import Productos from './pages/Productos.js';
import Logout from './pages/Logout.js';
import GaleriaVideo from './pages/GaleriaVideos.js';
/* import Juego from './pages/Juego.js'; */

import DataVideos from './components/dataVideos.js';
import DataVideosMov from './components/dataVideosMov.js';
import DataVideosIntro from './components/dataVideosIntro.js';
import DataVideosIntroMov from './components/dataVideosIntroMov.js';
import DataVideosInicio from './components/dataVideosInicio.js';

import NavBar from './components/Navbar.js';
import NavBarSign from './components/NavbarSign.js';
import TopStart from './components/topStart.js';
import ScrollToTop from './components/scrollTop';
/* import Preloader from './components/preloader.js'; */
import ScrollToTopButton from "./components/scrollToTopButton.js";
import FooterCSS from "./components/footerO.js";
import FooterCSSSign from "./components/footerOSign.js";
import axios from 'axios';
import getMetaInfo from './components/metatags.js';
/* SADMIN - PRODUCTOS */
import ProductosSAdmin from './pages/ProductosSadmin.js'
import DataXCategoria from './pages/DGProductos.js'
import Loader from './components/preloader.js'

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Detecta cambios en la ruta
  // Verificar autenticación en el backend
  const verifyAuth = async () => {
    try {
        const response = await axios.get('https://mp.toytek.cl/security/verify-token', {
          withCredentials: true, // Incluye cookies automáticamente
        });
        console.log("Token Verificado:", response.data);
        setIsAuth(true);
      
    } catch (error) {
      console.error("Error al verificar autenticación:", error);
      setIsAuth(false);
    } 
  };

  useEffect(() => {
    const pathsWithoutLoading = ["/collections/", "/products/", "/cart", "/account/register", "/account/login", "/pages/contact"]; // Paths donde quieres evitar el loading
    const shouldShowLoading = !pathsWithoutLoading.some((path) =>
      location.pathname.includes(path)
    );

    const initialize = async () => {
      setLoading(true); // Muestra el indicador de carga

      // Verifica autenticación al cargar la página
      await verifyAuth();
      if (shouldShowLoading) {
       /*  setLoading(true);
        setTimeout(() => setLoading(false), 2000); // Simula un tiempo de carga */
        // Simula una carga inicial (puedes personalizarlo)
        const timer = setTimeout(() => {
          setLoading(false);
      }, 2000); // 2 segundos

      return () => clearTimeout(timer);
      } else {
        setLoading(false);
      }
    };

    initialize();
  }, [location.pathname]); // Solo depende de la ruta actual

  useEffect(() => {
    const token = localStorage.getItem('authTokenTK'); // Obtén el token de localStorage
    if (token) {
      setIsAuth(true);
    } else {
      verifyAuth(); // Verifica si no hay token almacenado
    }
  }, []);

  const loaderOverlayStyle = {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "#f8f9fa", // Fondo semitransparente
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Asegura que el loader esté por encima de todo
  };
  
  if (loading) {
    // Muestra un spinner o indicador de carga mientras se verifica la autenticación
    return (
      <div style={loaderOverlayStyle}>
        <Loader className="justify-content-center"/>
      </div>
    );
  }
  
  return (
    <div>
    {/* Meta tags globales */}
    {getMetaInfo({
          title: 'Juguetes Retro Nostálgicos Coleccionables de los 70/80/90',
          description: 'Nuestra tienda está dedicada a los fanáticos de los juguetes clásicos y coleccionables de series de TV y cartoons de los años 70s 80s y 90s.',
          thumbnail: 'https://toytek.cl/assets/logo/toytek.jpg?&height=750&width=1200',
          type: 'website',
        })}
      <TopStart />
      {isAuth ? <NavBarSign /> : <NavBar />}
      <ScrollToTop />
      {/* {loading && <Preloader />} */}
      <Routes>
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/" element={isAuth ? (<RequireAuth isAuth={isAuth}><PortadaSign /></RequireAuth>) : (<Portada />)}/>
        <Route path="/home" element={isAuth ? (<RequireAuth isAuth={isAuth}><PortadaSign /></RequireAuth>) : (<Portada />)}/>
        <Route path="/sadmin/productos/:catName" element={<RequireAuth isAuth={isAuth}><DataXCategoria /></RequireAuth>} />
        <Route path="/sadmin/colecciones/" element={<RequireAuth isAuth={isAuth}><ProductosSAdmin /></RequireAuth>} />
      {/*   <Route path="/sadmin/productos/:catName" element={<DataXCategoria />} />
        <Route path="/sadmin/colecciones/" element={<ProductosSAdmin />} /> */}
        {/*Galerías de Videos*/}
        <Route path="/galeria-de-videos/" element={<DataVideosInicio />} />
        <Route path="/galeria-de-videos/cartoons/" element={<DataVideosIntro />} />
        <Route path="/galeria-de-videos/cartoons/:url2" element={<DataVideos />} />
        <Route path="/galeria-de-videos/cartoons/view/:url" element={<GaleriaVideo />} />
        <Route path="/galeria-de-videos/movies/" element={<DataVideosIntroMov />} />
        <Route path="/galeria-de-videos/movies/:url2" element={<DataVideosMov />} />

        {!isAuth ? <Route path="/account/login" element={<Login setAuth={setIsAuth} />} />: ''}
        <Route path="/account/register" element={<RequireAuth isAuth={!isAuth}><Registro /></RequireAuth>} />
        <Route path="/account/logout" element={<RequireAuth isAuth={isAuth}><Logout /></RequireAuth>} />
        <Route path="/account/unsubscribe" element={<Unsubscribe />} />
        <Route path="/account/me" element={<Me />} />

        <Route path="/pages/contact" element={<Contacto />} />
        <Route path="/pages/pagos-y-despachos" element={<PagosDespachos />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/chatbot" element={<TestChatBot />} />
        <Route path="/compra-exitosa" element={<OrdenCompraOk />} />
        <Route path="/hubo-un-problema" element={<OrdenCompraError />} />
        <Route path="/compra-pendiente" element={<OrdenCompraPendiente />} />
        <Route path="/search" element={<Search />} />
        
        <Route path="/products/:url" element={<DetalleProducto />} />
        <Route path="/collections/all" element={<Productos categoriaId="0" categoriaNombre="Todos los productos"/>} />
        <Route path="/collections/figuras-de-accion" element={<Productos categoriaId="1" categoriaNombre="Acción" />} />
        <Route path="/collections/figuras-articuladas" element={<Productos categoriaId="2" categoriaNombre="Articuladas" />} />
        <Route path="/collections/autitos" element={<Productos categoriaId="3" categoriaNombre="Autitos" />} />
        <Route path="/collections/figuras-monocromaticas" element={<Productos categoriaId="4" categoriaNombre="Monocromáticas" />} />
        <Route path="/collections/papeleria" element={<Productos categoriaId="5" categoriaNombre="Papelería" />} />
        <Route path="/collections/peliculas" element={<Productos categoriaId="6" categoriaNombre="Películas" />} />
        <Route path="/collections/figuras-plasticas" element={<Productos categoriaId="7" categoriaNombre="Plásticas" />} />
        <Route path="/collections/promocionales" element={<Productos categoriaId="8" categoriaNombre="Promocionales" />} />
        <Route path="/collections/figuras-de-pvc" element={<Productos categoriaId="9" categoriaNombre="PVC" />} />
        <Route path="/collections/rompecabezas" element={<Productos categoriaId="10" categoriaNombre="Rompecabezas" />} />
        <Route path="/collections/videojuegos" element={<Productos categoriaId="11" categoriaNombre="Videojuegos" />} />
        <Route path="/collections/variados" element={<Productos categoriaId="12" categoriaNombre="Variados" />} />
        <Route path="/collections/destacados" element={<Productos categoriaId="13" categoriaNombre="Productos Destacados" />} />
        <Route path="/collections/news" element={<Productos categoriaId="14" categoriaNombre="Productos Recientes" />} />
        <Route path="/collections/tazas" element={<Productos categoriaId="15" categoriaNombre="Tazas" />} />
        <Route path="/collections/poleras" element={<Productos categoriaId="16" categoriaNombre="Poleras" />} />
      </Routes>
      <ScrollToTopButton />
      {isAuth ? <FooterCSSSign /> : <FooterCSS />}
    </div>
  );

}

export default App;