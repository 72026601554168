import React from "react";
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';

function OrdenCompraError() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
    <Wsp></Wsp></>
);
}

export default OrdenCompraError;