 
import React, {useEffect} from 'react';
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Swal from 'sweetalert2'
/* import emailjs from '@emailjs/browser'; */

function OrdenCompraPendiente() {
  
  const confirmaPago = () => {
  
  let remainingTime = 10;
  let timerInterval;
Swal.fire({
  title: "Confirmación de Pago",
  html: "Tiempo restante <b>10</b> Minutos.",
  timer: 600000, // 150,000 milisegundos = 10 minutos
  timerProgressBar: true,
  showCancelButton: true, // Habilitar botón de cancelar
  cancelButtonText: "PAGADO", // Texto del botón de cancelación
  cancelButtonColor: "#3085d6",
  allowOutsideClick: false, // No permitir cerrar haciendo clic fuera del modal
  allowEscapeKey: false, // No permitir cerrar con la tecla "Esc"
  showConfirmButton: false, // Ocultar botón de confirmación
  didOpen: () => {
    

    Swal.showLoading();
    const timer = Swal.getPopup().querySelector("b");
    // Iniciar intervalo para actualizar el contador manualmente
    timerInterval = setInterval(() => {
      remainingTime--;
      timer.textContent = remainingTime;

      if (remainingTime <= 0) {
        clearInterval(timerInterval);
        timer.textContent = "Tiempo agotado"; // Mensaje cuando el tiempo se agote
      }
    }, 60000); // Actualizar cada minuto
  },
  willClose: () => {
    clearInterval(timerInterval);
  }
}).then((result) => {
  if (result.dismiss === Swal.DismissReason.cancel) {
    // Evitar cierre automático después de hacer clic en PAGADO
    return Swal.fire({
      title: "Confirmación de Pago",
      text: "¿Estás seguro de que has realizado el pago?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, confirmado",
      cancelButtonText: "No, continuar esperando",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        console.log("Pago confirmado");
   /*      const urlParent3 = 'https://toytek.cl/compra-exitosa';
                let win3 = window.open(urlParent3, '_parent');
                win3.focus(); */
        // Procesar la lógica del pago aquí
      } else {
      /*   const urlParent4 = '/compra-pendiente';
        let win4 = window.open(urlParent4, '_parent');
        win4.focus(); */
      }
    });
  }
});
};

useEffect(() => {
  confirmaPago();
}, []);



return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
    <Wsp></Wsp></>
);
}

export default OrdenCompraPendiente;