import React, { useState,useEffect, useRef } from 'react';
import ChatBot from 'react-simple-chatbot';
/* import styled from 'styled-components'; */
/* import Wsp from '../components/wsp.js'; */
import '../App.css';
import { FloatingWhatsApp  } from 'react-floating-whatsapp';



  const App = () => {
    const [showWhatsApp, setShowWhatsApp] = useState(false);
    

const CustomLinkMessage = () => {
  return (
    <div>
      Aquí tienes más información sobre nuestros productos: 
      <a href="/home#Productos" target="_blank" rel="noopener noreferrer"> 🔗Categoría de Productos</a>
    </div>
  );
};

/* useEffect(() => {
  const botBubbles = document.querySelectorAll('.rsc-ts-bubble');
  botBubbles.forEach((bubble) => {
    bubble.style.backgroundColor = '#e0f7fa !important';
    bubble.style.color = '#0062f3 !important';
  });

  const userBubbles = document.querySelectorAll('.rsc-ts-user .rsc-ts-bubble div');
  userBubbles.forEach((bubble) => {
    bubble.style.backgroundColor = '#fcfdce !important';
    bubble.style.color = 'white !important';
  });
}, []); */
/* const CustomLinkMessageWSP = () => {
  return (
    <div>
      Perfecto, te redirigiré a un agente:
      <a rel="noopener noreferrer"  onClick={() => window.open('https://wa.me/+56999303909', '_blank')} >Categoría de Productos</a>
    </div>
  );
}; */
const whatsAppRef = useRef(null);

const steps = [
    {
      id: '1',
      message: '¡Hola! Bienvenido a nuestra tienda de Juguetes Retro, Clásicos y Coleccionables. Tenemos figuras, juegos y juguetes de promociones, de series de TV, de películas y de dibujos animados. ¿Cuál es tu nombre?',
      trigger: '2',
      delay : 4000,
    },
    {
      id: '2',
      user: true,
      trigger: '3',
    },
    {
      id: '3',
      message: '¡Encantado de conocerte {previousValue}!',
      trigger: '4',
    },
    {
      id: '4',
      message: '¿Cómo te encuentras hoy?',
      trigger: '5',
    },
    {
      id: '5',
      user: true,
      trigger: '6',
    },
    {
      id: '6',
      message: 'Me alegra saber que te encuentras {previousValue}. ¿En qué te puedo ayudar hoy?',
      trigger: '7',
    },
    {
      id: '7',
      options: [
            { value: 2, label: 'Saber más de la tienda', trigger: 'tienda' },
            { value: 1, label: 'Saber más de los productos', trigger: 8 },
            { value: 4, label: 'Consultar por un producto específico', trigger: 'consulta' },
            { value: 3, label: 'Hablar con una persona real', trigger: 'openWhatsApp' },
          ],
    },
    { id: 'tienda', message: 'Nuestra tienda está dedicada a los fanáticos de los juguetes clásicos y coleccionables de series de TV y cartoons de los años 70s 80s y 90s. ¿Te interesa saber más sobre nuestras categorías actuales?', end: true },
        { id: 'consulta', message: 'Por favor, escribe...', end: true },
    {
      id: '8',
      component: <CustomLinkMessage />,
      trigger: 'end',
    }/* ,
    {
      id: '9',
      message: 'Perfecto, te redirigiré a un agente.',
      trigger: '10', // Aquí se abrirá WhatsApp
    } ,
    {
      id: '10',
      component: <CustomLinkMessageWSP />,
      trigger: 'end',
    }*/,
    {
      id: 'openWhatsApp',
      message: 'Abriendo WhatsApp...',
      trigger: () => {
        setShowWhatsApp(true); // Abrir el componente de WhatsApp
        return 'end'; // Continuar al siguiente paso
      },
    },
    {
      id: 'end',
      message: 'Gracias por tu consulta. ¡Que tengas un buen día!',
      end: true,
    },
  ];

  useEffect(() => {
    // Simula un clic en el botón flotante para abrir el chat
    if (whatsAppRef.current) {
      const button = whatsAppRef.current.querySelector('.floating-whatsapp-button'); // Clase CSS del botón
      if (button) {
        button.click(); // Simula el clic para abrir el chat
      }
      button.destroy();
    }
  }, [showWhatsApp]);

    return (
      <div >
        <ChatBot 
            headerTitle="Asistente Virtual" 
            steps={steps} 
            enableInput={true} 
            placeholder="Escriba su mensaje"
            customDelay={1000}
            botAvatar="/assets/logo/T.jpg"

            />
            {/* <button onClick={() => setShowWhatsApp(true)}>Abrir WhatsApp</button> */}
    {showWhatsApp && (  <div ref={whatsAppRef}><FloatingWhatsApp style={{color:'black'}} phoneNumber="+56 9 9930 2909" 
                            accountName="TOYTEK.CL" 
                            chatMessage="Hola, en que puedo ayudarte?"
                            placeholder="Escriba aquí su consulta..."
                            statusMessage="hola@toytek.cl"
                            avatar="/assets/logo/T.jpg"
                            notificationDelay="3"
                            messageDelay="3"
                            allowClickAway={true}
                            notification
                            notificationSound
                            /></div>)}
      </div>
    );
  };
export default App;